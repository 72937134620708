import React from "react";
import { MoneyConvertor } from "../../MoneyConvertor";

const Header = ({
  flagsPricingSendities,
  location,
  country_code,
  lang,
  data,
}) => {
  return (
    <div className="banner">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center">
          <MoneyConvertor
            locationName="remesas"
            headerTextData={data.title}
            location={location.href}
            country_code={country_code}
            flagsPricingSendities={flagsPricingSendities}
            lang={lang}
          />
        </div>
      </div>
    </div>
  );
};

export { Header };
